<template>
  <div class="box">
    <div class="header">
      <div class="header-search">
        <van-nav-bar
          :title="title"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
        >
        </van-nav-bar>
      </div>
    </div>
    <div class="content contentbox loadingbox" v-if="loadingfalg">
      <van-loading size="24px" vertical>加载中...</van-loading>
    </div>
    <div class="content contentbox" v-if="showage && !loadingfalg">
      <BasicTop :basicTop="basicTop" numberrows="2" />
      <Steps
        :levels="levels"
        v-if="levels && appStatus == 1"
        :hierarchy="hierarchy"
      />
      <div class="content-show">
        <van-tabs v-model="active" :animated="true">
          <van-tab v-for="tab in tabs" name="a" :key="tab.id" :title="tab.title">
            <van-list :finished="true" finished-text="没有更多了">
              <van-collapse v-model="activeNames">
                <van-collapse-item v-for="(item, idx) in tab.collapse" :key="item.id" :title="item.title" :name="`${idx + 1}`" icon="label">
                  <van-cell-group class="group">
                    <van-cell v-for="(list, index) in item.lists" :key="index" :title="list.title" :value="`${details[list.value] || ''}`" />
                  </van-cell-group>
                </van-collapse-item>
              </van-collapse>
            </van-list>
          </van-tab>

          <van-tab v-for="tab in mateTabs" name="b" :key="tab.id" :title="tab.title">
            <van-list :finished="true" finished-text="没有更多了">
              <van-collapse v-model="activeNames">
                <van-cell-group class="group">
                  <van-cell title="数量合计" :value="`${details.amount}`" />
                  <van-cell title="总金额（人民币）" :value="`${details.bidprice}`" />
                </van-cell-group>

                <van-collapse-item v-for="(item, idx) in tab.collapse" :key="item.id" :title="item.title" :name="`${idx + 1}`" icon="label">
                  <van-cell-group class="group">
                    <van-cell v-for="(list, index) in item.lists" :key="index" :title="list.title" :value="`${mateList[idx][list.value] || ''}`" />
                  </van-cell-group>
                </van-collapse-item>
              </van-collapse>
            </van-list>
          </van-tab>
          <!-- <van-tab title="详情页" name="a" v-if="appStatus == 1">
            <van-list :finished="true" finished-text="没有更多了">
              <van-collapse v-model="activeNames">
                <van-collapse-item title="关联第一阶段招标" name="1" icon="label">
                  <van-cell-group class="group">
                    <van-cell v-for="item in relatedInfo" :key="item.title" :title="item.title" :value="`${details[item.value]}`" />
                  </van-cell-group>
                </van-collapse-item>

                <van-collapse-item title="关联第一阶段招标" name="1" icon="label">
                  <van-cell-group class="group">
                    <van-cell v-for="item in relatedInfo" :key="item.title" :title="item.title" :value="`${details[item.value]}`" />
                  </van-cell-group>
                </van-collapse-item>
              </van-collapse>
            </van-list>
          </van-tab> -->


          <!-- <van-tab title="报价信息" name="b" v-if="appStatus == 1">
            <van-list :finished="true" finished-text="没有更多了">
              <van-collapse v-model="activeNames">
                <van-collapse-item title="包1" name="1" icon="label">
                  <van-cell-group class="group">
                    <van-cell v-for="item in basicInfo" :key="item.title" :title="item.title" :value="`${details[item.value]}`" />
                  </van-cell-group>
                </van-collapse-item>
              </van-collapse>
            </van-list>
          </van-tab>

          <van-tab title="审批记录" name="d" v-if="appStatus != 1">
            <div
              class="record"
              v-for="(item, index) in details.wfapproveinfoVOList"
              :key="index"
            >
              <van-cell-group>
                <van-cell title="审批层级" :value="item.node"></van-cell>
                <van-cell title="审批节点" :value="item.nodename"></van-cell>
                <van-cell title="审批人" :value="item.userName"></van-cell>
                <van-cell title="审批时间" :value="item.enddatetime"></van-cell>
                <van-cell title="审批结论" :value="item.resultType"></van-cell>
                <van-cell
                  title="审批意见"
                  :value="item.result"
                  v-if="item.resultType == '不同意'"
                ></van-cell>
              </van-cell-group>
            </div>
          </van-tab> -->
        </van-tabs>
      </div>
    </div>
    <div
      class="footer"
      v-if="showage == true && !loadingfalg"
    >
      <div class="footer-left" @click="agree" v-if="show">
        <span><van-icon name="success" size="20"/></span>
        <span>同意</span>
      </div>
      <div class="footer-right" @click="noagree" v-if="show">
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
      <div class="footer-left" v-if="!show">
        <span><van-icon name="success" size="20"/></span>
        <span>提交中</span>
      </div>
      <div class="footer-right" v-if="!show">
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
    </div>
    <Noagree v-if="!showage" :submitflag="submitflag" />
  </div>
</template>
<script>
import { Dialog } from "vant";
import { Toast } from "vant";
export default {
  data() {
    return {
      loadingfalg: false,
      submitflag: false,
      show: true,
      showage: true,
      title: "",
      loading: false,
      finished: true,
      isLoading: false,
      active: "a",
      activeNames: ["1", "2", "3", "4", "5", "6"],
      details: [],
      mateList: [],
      levels: null,
      hierarchy: "",
      basicTop: {
        data: [],
        columns: [
          {
            prop: "goods",
            label: "招标物资",
          },
          {
            prop: "strCorpName",
            label: "供应商",
          },
          {
            prop: "quotetopic",
            label: "报价书名称",
          },
          {
            prop: "totalAmount",
            label: "中标金额（人民币）",
          }
        ],
      },

      
      tabFirst: {

      },

      tabs: [
        {
          id: 1,
          title: '详情页',
          collapse: [
            { 
              id: 1,
              title: '基本信息',
              lists: [
                {title: '报价书标题', value: 'quotetopic'},
                {title: '报价有效期', value: 'quotebillEndTimeSplit'},
                {title: '中标物资', value: 'goods'},
                {title: '中标金额（人民币）', value: 'totalAmount'},
                {title: '报价备注', value: 'quotebillRemark'}
              ]
            },
            { 
              id: 2,
              title: '联系人信息',
              lists: [
                {title: '报价单位', value: 'strCorpName'},
                {title: '地址', value: 'addr'},
                {title: '联系人', value: 'operaname'},
                {title: '电话', value: 'tel'},
                {title: '传真', value: 'fax'},
                {title: 'E-mail', value: 'email'},
                {title: '报价时间', value: 'ts'},
                {title: '招标单位', value: 'compName'},
                {title: '地址', value: 'address'},
                {title: '联系人', value: 'contactor'},
                {title: '电话', value: 'zbTel'},
                {title: '传真', value: 'zbFax'},
                {title: 'E-mail', value: 'zbEmail'},
                {title: '开标时间', value: 'opentime'},
              ]
            }
          ]
        }
      ],

      mateTabs: [
        {
          id: 2,
          title: '报价信息',
          collapse: [
            // { 
            //   id: 1,
            //   title: '基本信息',
            //   lists: [
            //     {title: '数量合计', value: 'amount'},
            //     {title: '总金额（人民币）', value: 'totalAmount'}
            //   ]
            // },
            // { 
            //   id: 2,
            //   title: '序号1',
            //   lists: [
            //     {title: '物资描述', value: 'goodsName'},
            //     {title: '物资编码', value: 'goods'},
            //     {title: '数量', value: 'qty'},
            //     {title: '计量单位', value: 'measunit'},
            //     {title: '交货期', value: 'dateDelivery'},
            //     {title: '单价（人民币）', value: 'price'},
            //     {title: '总价（人民币）', value: 'amount'},
            //     {title: '是否含税', value: 'istax'},
            //     {title: '税率', value: 'taxName'},
            //     {title: '运费（人民币）', value: 'free'},
            //     {title: '其他费用（人民币）', value: 'other'}
            //   ]
            // }
          ]
        }
      ],
    };
  },
  async created() {

    this.getStockaffirmDetail();
    // getBidcompetitiveView
    // const params = {
    //   bidcompetitiveId: 824701717,
    //   // bidcompetitiveId
    //   statusType: 1
    // };
    // let data = await this.$api.ec.getBidcompetitiveView(params)
    // console.log('dataaaaaaaaa', data)
  },
  computed: {
    stockaffirmId() {
      return this.$route.query.id;
    },
    appStatus() {
      return this.$route.query.appStatus;
    },
  },
  methods: {
    async getStockaffirmDetail() {
      const params = {
        bidNotifyId: this.$route.query.id
      };
      let data = await this.$api.ec.getItemizedQuotationDetail(params);

      this.title = this.$route.query.bidfilecode

      console.log('这是啥', data)
      if (data) {
        this.loadingfalg = false;
      }

      // 分项报价物资信息
      const billpartParams = {
        pageIndex: 1,
        pageSize: 10,
        quotebillId: data.quotebillId
      }

      let billpart = await this.$api.ec.getQuoteBillPartView(billpartParams)

      console.log('分项报价', billpart, JSON.stringify(billpart.data))

      billpart.data.map(item => {
        item.dateDelivery = `${item.startcontime && item.startcontime.split(' ')[0]} - ${item.endcontime && item.endcontime.split(' ')[0]}`
        item.istax = item.iftax == 1 ? '是' : '否'
      })

      this.mateList = billpart.data

      this.mateList.forEach((item, index) => {
        this.mateTabs[0].collapse.push({
          id: index + 1,
          title: `序号${index + 1}`,
          lists: [
            {title: '物资描述', value: 'goodsName'},
            {title: '物资编码', value: 'goods'},
            {title: '数量', value: 'qty'},
            {title: '计量单位', value: 'measunit'},
            {title: '交货期', value: 'dateDelivery'},
            {title: '单价（人民币）', value: 'price'},
            {title: '总价（人民币）', value: 'amount'},
            {title: '是否含税', value: 'istax'},
            {title: '税率', value: 'taxName'},
            {title: '运费（人民币）', value: 'free'},
            {title: '其他费用（人民币）', value: 'other'}
          ]
        })
      })

      this.details = data
      
      // this.details.quotationDateSp = data.quotationDate.split(' ')[0]
      // this.details.opentimeSp = data.opentime.split(' ')[0]
      
      this.details.totalAmount = data.bidprice
      this.details.quotebillEndTimeSplit = data.quotebillEndTime && data.quotebillEndTime.split(' ')[0]

      this.basicTop.data = data;
      this.levels = Number(data.levels)
      if (this.details.fileList) {
        this.details.fileList.map((item) => {
          item.suffix = item.title.substring(item.title.lastIndexOf(".") + 1);
        });
      }
      if (this.details.projectFileList) {
        this.details.projectFileList.map((item) => {
          item.suffix = item.title.substring(item.title.lastIndexOf(".") + 1);
        });
      }
      if (this.details.enquireBillFileList) {
        this.details.enquireBillFileList.map((item) => {
          item.suffix = item.title.substring(item.title.lastIndexOf(".") + 1);
        });
      }

      // data.wfnodeVOs && data.wfnodeVOs.map((item, index) => {
      //   console.log('dattttttt', data, item)
      //   if (item.isNodeaction == 1) {
      //     this.levels = index + 1;
      //     return;
      //   }
      // });
      // this.hierarchy = data.wfnodeVOs.length;
    },
    ondown(item) {
      let query = {
        id: item.id,
      };
      this.$router.push({ name: "preview", query });
    },
    async auditApply(o, message) {
      let params
      if (o == 2) {
        this.submitflag = true;
        params = {
          bidNotifyId: this.$route.query.id,
          lastupdate: this.details.lastupdate,
          audit: 0,
          leadexam: message,
          quotebillId: this.details.quotebillId,
          ts: this.details.ts
        };
      } else {
        params = {
          bidNotifyId: this.$route.query.id,
          lastupdate: this.details.lastupdate,
          audit: 1,
          leadexam: "同意",
          quotebillId: this.details.quotebillId,
          ts: this.details.ts
        };
      }
      let data = await this.$api.ec.confirmQuotation(params)

      console.log('data', data)
      if (data) {
        Toast.success("提交成功");
        setTimeout(() => {
          this.$router.push({
            name: "ecItemizedQuotation",
            query: { type: 1 },
          });
        }, 1000);
      }
    },
    onClickLeft() {
      if (this.showage) {
        this.$router.go(-1);
        // this.$router.push({ name: "" });
      } else {
        this.showage = true;
        this.title = "采购方案审批详情";
      }
    },
    onRefresh() {
      this.loading = true;
    },
    onCompression() {
      Dialog.alert({
        message: "压缩包请到电脑端查看",
      }).then(() => {
        // on close
      });
    },
    onLoad() {},
    listDetails() {},
    agree() {
      // let messageval = "";
      // if (
      //   this.details.ifonly == 1 &&
      //   this.details.stockaffirmExclusiveresultsVO &&
      //   this.details.stockaffirmExclusiveresultsVO.publicitytype == 1
      // ) {
      //   messageval = "独家结果审批全部通过后将自动发出独家结果公示，是否继续?";
      // } else if (
      //   this.details.ifonly == 1 &&
      //   this.details.stockaffirmExclusiveresultsVO &&
      //   this.details.stockaffirmExclusiveresultsVO.publicitytype == 2
      // ) {
      //   messageval = "当前独家结果为独家采购免公示，是否继续?";
      // } else {
      //   messageval = "是否同意";
      // }
      Dialog.confirm({
        title: "审批",
        message: "是否同意"
      })
        .then(() => {
          this.show = false;
          this.auditApply();
        })
        .catch(() => {
          // on cancel
        });
    },
    noagree() {
      this.title = "审批";
      this.showage = false;
    },
  },
};
</script>
<style lang="less" scoped>
.footer-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #70e6ea;
}
.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f77269;
}
/deep/.group {
  .van-cell {
    color: #8f8f8f;
  }
}
/deep/.van-cell {
  background-color: transparent;
}
/deep/.van-cell {
  font-size: 12px;
}
/deep/.van-collapse-item__content {
  font-size: 12px;
  padding: 0;
}
/deep/.van-cell__value {
  color: #333;
}
.supplies {
  margin-top: 10px;
  min-height: 160px;
  background-color: #fff;
  padding: 0.3rem;
  border-radius: 9px;
  margin: 0.3rem;
  .supplies-title {
    font-weight: 400;
    line-height: 25px;
    font-size: 13px;
  }
  .supplies-text {
    color: #8f8f8f;
    line-height: 25px;
  }
}
.record {
  margin-top: 10px;
}
</style>
